<template>
  <footer class="footer">
    <div></div>
    <div class="text-center py-3 text-primary">
      ©
      <a href="http://manawork.com" title="มานะเวิร์ค MANAWORK">manawork</a>.
      Powered by
      <a href="http://lffintech.co.th" title="บริษัท แอลเอฟฟินเทค จำกัด"
        >LFFintech Co., Ltd.</a
      >
    </div>
  </footer>
</template>
<script>
export default {};
</script>