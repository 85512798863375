var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('DefaultTemplate',[_c('section',{staticClass:"mg-t-3 section"},[_c('el-row',{attrs:{"gutter":20}},[_c('el-col',{attrs:{"md":24,"lg":4,"offset":0}},[_c('h2',[_vm._v("Secret Key")])])],1),_c('el-row',{staticClass:"flex-warp",attrs:{"gutter":20,"type":"flex"}},[_c('el-col',{attrs:{"md":6,"lg":4,"xl":3,"offset":0}},[_c('label',[_vm._v("Secret Key")])]),_c('el-col',{attrs:{"md":18,"lg":20,"xl":21,"offset":0}},[_c('section',{staticClass:"field"},[(!_vm.addsecret)?_c('div',[(_vm.secret)?_c('div',[_vm._v(" "+_vm._s(_vm.secret)+" "),_c('span',{staticClass:"mg-l-3 cr-pointer",on:{"click":function($event){return _vm.copyText(_vm.secret)}}},[_c('el-popover',{attrs:{"popper-class":"bg-dark","placement":"right","trigger":"click","content":"Coppied","width":"50"}},[_c('i',{staticClass:"el-icon-copy-document fas",attrs:{"slot":"reference"},slot:"reference"})])],1)]):_c('div',[_c('div',[_vm._v("-")]),_c('div',{staticClass:"mg-t-4"},[_c('el-button',{on:{"click":function($event){_vm.addsecret = true}}},[_vm._v("Add")])],1)])]):_c('div',[_c('el-col',{attrs:{"md":18,"lg":12}},[_c('el-form',{ref:"ruleForms",attrs:{"model":_vm.ruleForm,"label-position":"top"}},[_c('el-form-item',{attrs:{"prop":"name","label":"Name","rules":[
                    {
                      required: true,
                      message: 'Please input Name',
                      trigger: 'blur',
                    } ]}},[_c('el-input',{model:{value:(_vm.ruleForm.name),callback:function ($$v) {_vm.$set(_vm.ruleForm, "name", $$v)},expression:"ruleForm.name"}})],1),_vm._l((_vm.ruleForm.requests),function(domain,index){return _c('el-form-item',{key:index,staticClass:"is-flex",attrs:{"label":'Domain',"prop":'requests.' + index + '.value',"rules":{
                    required: true,
                    message: 'domain can not be null',
                    trigger: 'blur',
                  }}},[_c('el-input',{attrs:{"placeholder":"https://example.com"},model:{value:(domain.value),callback:function ($$v) {_vm.$set(domain, "value", $$v)},expression:"domain.value"}}),(_vm.ruleForm.requests.length > 1)?_c('el-button',{attrs:{"type":"danger"},on:{"click":function($event){$event.preventDefault();return _vm.ruleForm.requests.splice(index, 1)}}},[_vm._v("Delete")]):_vm._e()],1)}),_c('el-form-item',[_c('el-button',{attrs:{"type":"success"},on:{"click":function($event){return _vm.validateForm('ruleForms')}}},[_vm._v("Submit")]),_c('el-button',{on:{"click":function($event){return _vm.ruleForm.requests.push({ value: '' })}}},[_vm._v("Add domain")])],1)],2)],1)],1)])])],1)],1),_c('el-divider')],1)}
var staticRenderFns = []

export { render, staticRenderFns }