// @ts-nocheck
import Vue from "vue";
import { HTTP } from "@/service/axios";
import { MessageBox } from "element-ui";

export default {
  CheckAuth: () => {
    MessageBox.confirm("กรุณาเข้าสู่ระบบใหม่อีกครั้ง", {
      confirmButtonText: "OK",
      showCancelButton: false,
      type: "error",
    }).then(() => {
      setTimeout(() => {
        window.localStorage.eram.clear();
      }, 200);
      window.localStorage.removeItem("mana-dev");
      delete HTTP.defaults.headers.common.Authorization;
      setTimeout(() => {
        window.location.href = "/";
      }, 250);
    });
  },
  alertFailError: () => {
    MessageBox.alert(
      "Oops! something went wrong. Please contact admin or try again later",
      {
        confirmButtonText: "OK",
        type: "error",
      }
    );
  },
  alertCatchError: (e) => {
    MessageBox.alert(`${e}`, {
      confirmButtonText: "OK",
      type: "error",
    });
  },
};
