<template>
  <a href="/" class="d-flex ai-center nav-logo">
    <img
      src="@/assets/logo.png"
      alt="มานะ ทำงานด้วยกันออนไลน์"
      style="max-width: 80px"
    />
    <div class="mg-x-2">Developers</div>
  </a>
</template>
<script>
export default {};
</script>