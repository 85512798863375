<template>
  <DefaultTemplate>
    <section class="mg-t-3 section">
      <el-row :gutter="20">
        <el-col :md="24" :lg="4" :offset="0"><h2>Secret Key</h2></el-col>
      </el-row>
      <el-row :gutter="20" class="flex-warp" type="flex">
        <el-col :md="6" :lg="4" :xl="3" :offset="0"
          ><label>Secret Key</label></el-col
        >
        <el-col :md="18" :lg="20" :xl="21" :offset="0">
          <section class="field">
            <div v-if="!addsecret">
              <div v-if="secret">
                {{ secret }}
                <span class="mg-l-3 cr-pointer" @click="copyText(secret)"
                  ><el-popover
                    popper-class="bg-dark"
                    placement="right"
                    trigger="click"
                    content="Coppied"
                    width="50"
                  >
                    <i slot="reference" class="el-icon-copy-document fas"></i
                  ></el-popover>
                </span>
              </div>
              <div v-else>
                <div>-</div>
                <div class="mg-t-4">
                  <el-button @click="addsecret = true">Add</el-button>
                </div>
              </div>
            </div>
            <div v-else>
              <el-col :md="18" :lg="12">
                <el-form :model="ruleForm" ref="ruleForms" label-position="top">
                  <el-form-item
                    prop="name"
                    label="Name"
                    :rules="[
                      {
                        required: true,
                        message: 'Please input Name',
                        trigger: 'blur',
                      },
                    ]"
                  >
                    <el-input v-model="ruleForm.name"></el-input>
                  </el-form-item>

                  <el-form-item
                    v-for="(domain, index) in ruleForm.requests"
                    :label="'Domain'"
                    :key="index"
                    class="is-flex"
                    :prop="'requests.' + index + '.value'"
                    :rules="{
                      required: true,
                      message: 'domain can not be null',
                      trigger: 'blur',
                    }"
                  >
                    <el-input
                      v-model="domain.value"
                      placeholder="https://example.com"
                    ></el-input
                    ><el-button
                      type="danger"
                      @click.prevent="ruleForm.requests.splice(index, 1)"
                      v-if="ruleForm.requests.length > 1"
                      >Delete</el-button
                    >
                  </el-form-item>
                  <el-form-item>
                    <el-button type="success" @click="validateForm('ruleForms')"
                      >Submit</el-button
                    >
                    <el-button @click="ruleForm.requests.push({ value: '' })"
                      >Add domain</el-button
                    >
                  </el-form-item>
                </el-form>
              </el-col>
            </div>
          </section>
        </el-col>
      </el-row>
    </section>
    <el-divider></el-divider>
  </DefaultTemplate>
</template>
<script>
import "@/mixin/DefaultTemplate";
import api from "@/service/ApiKey";
export default {
  data() {
    return {
      secret: "",
      addsecret: false,
      ruleForm: {
        name: "",
        requests: [
          {
            value: ""
          }
        ]
      }
    };
  },
  mounted() {
    this.getSecret();
  },
  methods: {
    copyText(TextToCopy) {
      var TempText = document.createElement("input");
      TempText.value = TextToCopy;
      document.body.appendChild(TempText);
      TempText.select();
      document.execCommand("copy");
      document.body.removeChild(TempText);
    },
    validateForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.addSecret();
        }
      });
    },
    async getSecret() {
      let res = await api.getSecretKey(this.token);
      if (res.data.success) {
        this.secret = res.data.obj.apiKey;
      }
    },
    async addSecret() {
      let obj = {
        name: this.ruleForm.name,
        requests: []
      };
      this.ruleForm.requests.forEach(item => {
        obj.requests.push(item.value);
      });
      let res = await api.addSecretKey(this.token, obj);
      if (res.data.success) {
        this.secret = res.data.obj;
        ({
          message: "Success.",
          type: "success"
        });
      } else {
        this.secret = null;
      }
      this.addsecret = false;
    }
  }
};
</script>