// @ts-nocheck
import { HTTP } from "@/service/axios";
import alert from "@/service/Alert";
export default {
  getSecretKey: async (token) => {
    HTTP.defaults.headers.common.Authorization = `Bearer ${token}`;
    let res = await HTTP.get(`/register/api/key`).catch((e) => {
      console.log(`secretKey ERROR ${e}`);
      if (e == "Error: Request failed with status code 401") {
        alert.CheckAuth();
      } else {
        alert.alertCatchError(e);
      }
    });
    return res;
  },
  addSecretKey: async (token, obj) => {
    HTTP.defaults.headers.common.Authorization = `Bearer ${token}`;
    let res = await HTTP.post(`/register/api`, obj).catch((e) => {
      console.log(`secretKey ERROR ${e}`);
      if (e == "Error: Request failed with status code 401") {
        alert.CheckAuth();
      } else {
        alert.alertCatchError(e);
      }
    });
    return res;
  },
};
