<template>
  <header class="navbar">
    <div class="d-flex js-between pd-x-4 pd-y-6">
      <div>
        <div class="d-flex">
          <Logo />
          <Navbar />
        </div>
      </div>
      <div>
        <div v-if="!user">
          <el-button class="mg-t-3" @click="$router.push('/sign-in')">
            Log in
          </el-button>
        </div>
        <el-dropdown class="mg-t-3" v-else>
          <span class="el-dropdown-link">
            <div class="block">
              <el-avatar :size="32" :src="imgUrl + user.profileImg"></el-avatar>
            </div>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item route="/console"
              ><div @click="$router.push('/console')">
                <i class="el-icon-s-tools fas"></i> Developers Console
              </div></el-dropdown-item
            >

            <el-dropdown-item divided
              ><div class="cr-pointer text-center" @click="signout">
                Log out
              </div></el-dropdown-item
            >
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
  </header>
</template>
<script>
import Logo from "@/components/header/Logo";
import Navbar from "@/components/header/NavBar";
import { HTTP } from "@/service/axios";
export default {
  components: {
    Navbar,
    Logo
  },
  data() {
    return {
      imgUrl: process.env.VUE_APP_IMAGE_URL
    };
  },
  methods: {
    async signout() {
      setTimeout(() => {
        window.localStorage.eram.clear();
      }, 200);
      window.localStorage.removeItem("mana-dev");
      delete HTTP.defaults.headers.common.Authorization;
      setTimeout(() => {
        window.location.href = "/";
      }, 250);
    }
  }
};
</script>