<template>
  <div>
    <Header />
    <aside v-if="side" class="sidebar"></aside>
    <main class="page" :class="{ 'active-side': side }">
      <div class="container">
        <slot />
      </div>
    </main>
    <Footer />
  </div>
</template>
<script>
import Header from "@/components/header";
import Footer from "@/components/footer";
export default {
  props: {
    side: {
      type: Boolean,
      default: true
    }
  },
  components: {
    Header,
    Footer
  },
  data() {
    return {
      circleUrl:
        "https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png"
    };
  }
};
</script>