<template>
  <el-menu
    :default-active="activeIndex"
    mode="horizontal"
    @select="handleSelect"
  >
    <el-menu-item index="1">News</el-menu-item>
    <el-submenu index="2">
      <template slot="title">Product</template>
      <el-menu-item index="2-1">Mana Work API</el-menu-item>
    </el-submenu>
    <el-menu-item index="2">Documentation</el-menu-item>
  </el-menu>
</template>
<script>
export default {
  data() {
    return {
      activeIndex: ""
    };
  },
  methods: {
    handleSelect(key, keyPath) {
      console.log(key, keyPath);
    }
  }
};
</script>